@import "react-day-picker/dist/style.css";

.rdp {
  margin: .5em .2em .5em .2em;

  .rdp-caption {
    .rdp-caption_label {
      font-family: var(--chakra-fonts-heading);
      font-weight: var(--chakra-fontWeights-semibold);
      font-size: var(--chakra-fontSizes-xl);
      color: var(--chakra-colors-brand-700);
    }


    .rdp-nav_button {
      color: var(--chakra-colors-brand-100);

      &:hover {
        color: var(--chakra-colors-brand-700);
        background-color: transparent;
      }
    }
  }


  .rdp-months {
    width: 100%;

    .rdp-head_cell {
      font-size: var(--chakra-fontSizes-xs);
      font-weight: var(--chakra-fontWeights-semibold);
      text-transform: capitalize;
      color: var(--chakra-colors-gray-400);
    }

    .rdp-month {
      width: 100%;

      .rdp-table {
        width: 100%;
        max-width: 100%;

        .rdp-day {
          width: 100%;
          max-width: 100%;
          border-radius: 0;
          color: var(--chakra-colors-brand-700);

          &:hover:not(.rdp-day_selected):not([disabled]) {
            background-color: var(--chakra-colors-brand-100);
            color: var(--chakra-colors-brand-700);
          }

          &.rdp-day_selected {
            background-color: var(--chakra-colors-brand-700);
            color: var(--chakra-colors-brand-100);
          }
        }
      }
    }
  }
}
