.ol-attribution {
  bottom: 3px;
  right: 3px;
  background-color: var(--chakra-colors-whiteAlpha-200);
  border-radius: 50%;


  button {
    color: var(--chakra-colors-blackAlpha-700);
    background-color: var(--chakra-colors-whiteAlpha-400);
    border: 1px solid var(--chakra-colors-blackAlpha-500);
    border-radius: 50%;
    font-size: 14px;
  }

  ul {
    color: var(--chakra-colors-blackAlpha-700);
    text-shadow: none;
  }

  .collapsed {
    border-radius: 50%;
  }

  &:not(.ol-collapsed) {
    color: var(--chakra-colors-blackAlpha-700);
    background-color: var(--chakra-colors-whiteAlpha-400);
    border-radius: 5px;
  }
}

.ol-scale-line {
  bottom: 8px;
  left: 8px;
  background-color: var(--chakra-colors-whiteAlpha-200);

  .ol-scale-line-inner {
    color: var(--chakra-colors-black);
    background-color: var(--chakra-colors-brand-100);
    border: 1px solid var(--chakra-colors-blackAlpha-500);
    border-top: none;
  }
}

.ol-rotate {
  bottom: 30px;
  left: 10px;
  right: unset;
  top: unset;
  color: var(--chakra-colors-black);
  background-color: var(--chakra-colors-brand-100);
  border: 1px solid var(--chakra-colors-blackAlpha-500);

  button {
    background-color: var(--chakra-colors-brand-100);
  }
}

.hidden {
  display: none;
}
