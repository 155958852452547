.SplitPane {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: text;
  outline: none;
  position: relative;

  &.horizontal {
    flex-direction: column;
  }

  &.vertical {
    flex-direction: row;
  }

  &.Pane {
    position: relative;
    outline: none;
    overflow: hidden;
  }
}

.Resizer {
  position: relative;
  background: var(--chakra-colors-gray-100);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;

  &.horizontal {
    width: 100%;
    height: 12px;
    margin-top: -3px;
    cursor: row-resize;
  }

  &.vertical {
    width: 7px;
    margin-top: -3px;
    cursor: col-resize;
  }

  &.horizontal::after {
    content: "";
    border-bottom: 4px dotted var(--chakra-colors-primary-800);
    position: absolute;
    top: 8px;
    transform: translateY(-100%);
    right: 50%;
    display: inline-block;
    width: 20px;
  }

  &.vertical::after {
    content: "";
    border-bottom: 4px dotted var(--chakra-colors-primary-800);
    position: absolute;
    top: 50%;
    transform: rotate(90deg) translateY(6px);
    display: inline-block;
    width: 20px;
  }
}
