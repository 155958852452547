.no-code-editor {
  .react-flow {
    &__edge-path {
      stroke: var(--chakra-colors-gray-200);
      stroke-width: 2;
    }

    &__edges {
      z-index: 1001 !important;
    }

    &__handle-connecting {
      background-color: var(--chakra-colors-red-500) !important;
    }

    &__handle-valid {
      background-color: var(--chakra-colors-green-500) !important;
    }

    svg {
      body {
        background: transparent
      }

      .removableEdge-button {
        cursor: pointer;
        font-size: 10px;
        width: 15px;
        height: 15px;
        line-height: 1;
      }
    }

    &__minimap {
      width: 150px;
      height: 100px;
      background-color: transparent;
      
      &-mask {
        fill: rgba(0, 0, 0, 0.05);
        border-radius: 5px;
      }
    }
  }
}
